$titleFmaily: "Inter";
$subTitleFmaily: "Inter";
$txtFamily: "Inter";

// Size
$tiny: 10px;
$small: 12px;
$medium: 14px;
$large: 15px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 38px;

h1,
h2,
h3,
h4,
h5 {
  color: $primary;
  font-weight: 600;
  letter-spacing: 5px;
}

h1 {
  font-family: $titleFmaily;
  font-size: $xxxlarge;
  font-weight: 300;
}

h2 {
  font-family: $subTitleFmaily;
  font-size: $xxlarge;
  line-height: $xxlarge * 1.5;
}

h4 {
  font-family: $subTitleFmaily;
  font-size: $large;
}

h5 {
  font-family: $subTitleFmaily;
  font-size: $medium;
}

p,
li,
a {
  font-family: $txtFamily;
  font-size: $small;
  font-weight: 400;
  color: black;
}

.light {
  font-weight: 500;
}
