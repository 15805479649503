body {
  overflow-x: hidden;
  background-color: $bg;
  font-size: $small;
  min-height: 100vh;
  background-image: url("/assets/bg.png");
  background-size: cover;
  object-fit: cover;
  height: 100%;
}

p + p {
  margin: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.mt-m {
  margin-top: $m;
}

.mt-xl {
  margin-top: $xl;
}

.mb-m {
  margin-bottom: $m;
}

.mb-xl {
  margin-bottom: $xl;
}

button {
  width: auto;
  background: $primary;
  border: 1px solid $white;
  color: $altTxt;
  border-radius: 50px;
  transition: 0.7s;
  padding-left: $l;
  padding-right: $l;
  font-weight: 600;
  font-size: $tiny;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    162deg,
    rgba(0, 0, 0, 1) 0%,
    $primary 0%,
    $secondary 100%
  );

  &.full {
    width: 100%;
  }

  &.small {
    padding: $xs;
  }

  &.secondary {
    color: $primary;
    border: 1px solid $primary;
    border-color: linear-gradient(
      162deg,
      rgba(0, 0, 0, 1) 0%,
      $primary 0%,
      $secondary 100%
    );
    background: transparent;
  }
}

span.primary,
p.primary {
  color: $txt;
  font-weight: 600;
  font-family: $txtFamily;
}

#WEB3_CONNECT_MODAL_ID > div {
  z-index: 50;
}

.center {
  text-align: center;
}

.center-center {
  justify-content: center;
  align-items: center;
}

.end-center {
  justify-content: flex-end;
  align-items: center;
}

.center-end {
  justify-content: center;
  align-items: flex-end;
}

.start-center {
  justify-content: flex-start;
  align-items: center;
}

.center-start {
  justify-content: center;
  align-items: flex-start;
}

.opacity {
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;

  &.white {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.full-flex {
  flex: 1;
}

.divider {
  width: 100%;
  display: block;
  height: 15px;
  background-color: $bg;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.shadow {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 1);
  -moz-box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 1);
  box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 1);
}

.loading-overlay {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
}

.mobile-bg {
  background-image: url("/assets/bg.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
}

.rainbow {
  animation: colorRotate 2s linear 0s infinite;
}

@keyframes colorRotate {
  from {
    color: $primary;
  }
  50% {
    color: $secondary;
  }
  100% {
    color: $primary;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: auto;
    max-width: initial;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
